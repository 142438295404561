import { Card, CardActions, CardContent, Grid, Typography } from "@material-ui/core";
import React from "react";
import AlbumLayout, { useStyles } from "./album_layout";
import { useTranslation } from "react-i18next";
import Markdown from "./Markdown";
import { BackButton } from "./back_button";
import AdSense from 'react-adsense';


const Reference = (props:any) => {
    const pagePath = props.pageContext.pagePath;
    const reference = props.pageContext.reference;
    const { t } = useTranslation();
    const classes = useStyles();

    const image = reference.imageFile[0].childImageSharp.fluid;

    return (
      <React.Fragment>
        <Card className={classes.card} style={{ margin: "16px" }}>
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h5" component="h2">
              {reference.name}
            </Typography>
            <Typography>
              <Markdown>{reference.description}</Markdown>
            </Typography>
          </CardContent>

          <CardActions style={{ flexDirection: "column" }}>
            <BackButton />
          </CardActions>
        </Card>

        <Card className={classes.card} style={{ margin: "16px" }}>
          <CardContent className={classes.cardContent}>
            <div style={{ width: "276px" }}>
              <AdSense.Google
                client="ca-pub-8636622189012223"
                slot="3748774839"
                format="rectangle"
              />
            </div>
          </CardContent>
        </Card>
      </React.Fragment>
    );

}
export default Reference;