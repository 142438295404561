import React from "react";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { Link } from "gatsby";

export const BackButton = (props:any) => {
    const { t } = useTranslation();
    return (
      <Button size="small" color="secondary" onClick={(e) => window.history.back()}>
        {t("back")}
      </Button>
    );
}